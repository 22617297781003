header {
  /* height: 100vh; */
  padding-top: 7rem;
  overflow: hidden;
}

.header_container {
  text-align: center;
  height: 100%;
  position: relative;
}

.cta {
  margin-top: 2.5rem;
  display: flex;
  gap: 1.2rem;
  justify-content: center;
}

.header_socials {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
  position: fixed;
  left: 50px;
  bottom: 3rem;
}

.me {
  background: linear-gradient(var(--color-primary),transparent);
  width: 22rem;
  height: 30rem;
  position: absolute;
  left: calc(50% - 11rem);
  margin-top: 4rem;
  border-radius: 12rem 12rem 0 0;
  overflow: hidden;
  padding: 5rem 1.5rem 1.5rem 1.5rem;
}

.scroll_down {
  position: absolute;
  right: -2.3rem;
  bottom: 5rem;
  transform: rotate(90deg);
  font-weight: 200;
  font-size: 0.9rem;
}

.cartoon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-70%, -50%);
  width: 80vmin;
  height: 80vmin;
}


.cartoon div {
  position: absolute;
  box-sizing: border-box;
}

.b {
  border: 0.5vmin solid black;
}

.r {
  border-radius: 100%;
}

.hb::before
 {
  content: "";
  display: block;
  position: absolute;
}

.bubble {
  width: 30%;
  height: 20%;
  left: 73%;
  top: 28%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 2vmin;
  background: linear-gradient(var(--color-primary),transparent);
  box-shadow: 0 -0.25vmin, 0 0.125vmin;
  font-family: "Comic Sans", "Comic Neue", sans-serif;
}

.bubble::before {
  width: 40%;
  height: 100%;
  bottom: -51%;
  border-radius: 50%;
  left: 10%;
  box-shadow: 0.5vmin 0, 2vmin -0.5vmin #6c572c, 2vmin -0.5vmin 0 0.5vmin;
  clip-path: polygon(0% 49%, 150% 48%, 150% 100%, 0% 100%);
}


/* @media screen and (max-width: 1024px){
  header {
    height: 70vh;
  }
} */

@media screen and (max-width: 600px){
/*   header {
    height: 100vh;
  } */
  .header_socials, .scroll_down {
    display: none;
  }
}
